@mixin borderline($color:black) {
  &:before {
    position: absolute;
    top:0;
    left: 30px;
    content: '';
    width: 3px;
    height: 100%;
    background: $color;
    z-index: -2;
  }
}

@mixin borderlineafter($color:black) {
  &:before {
    position: absolute;
    top:0;//10px;
    right: 30px;
    content: '';
    width: 3px;
    height: calc(100% - 30px);//calc(100% - 40px);
    background: $color;
  }
}

@mixin borderlineaftersmall($color:black) {
  &:before {
    position: absolute;
    top:10px;
    right: 30px;
    content: '';
    width: 3px;
    height: calc(100% - 20px);
    background: $color;
  }
}
