.nav {
  position: absolute;
  top: 30px;
  left:200px;  
  right: 0;
  max-width: 1140px;  
  margin: 0 auto;
  padding: 0 60px 0 0;  
  z-index: 2;
  transition: all 200ms cubic-bezier(.92,.06,.12,.73);
  text-align: right;
  li {
    margin:0 30px 10px 30px;
    display: inline-block;
    a {
      display: block;
      text-decoration: none;
    }

    &.nav-title {
      a {
        color: $darkgreen;
        &:hover {
          color: white;
        }
      }
      display: none;
    }
    
  }
}

.social {
  display: none;
}

.scrolling {
  .nav {
    display: none;
    position: fixed;
    padding: 0;
    top:0;
    left:auto;
    right:-50%;
    width: 33%;
    height: 100%;
    background: $maincolor;
    text-align: left;
    li, a {
      display: block;
      margin: 10px 20px;
    }    

  }
}
.m-menu {

  position: fixed;
  top:30px;
  right:30px;
  display: none;
  z-index: 4;

  .mm {
    border:none;
    background:none;
    cursor: pointer;
    position: relative;
    display: block;
    width:26px;
    height:26px;
    outline:none;
    padding: 0; // ios has a bug otherwise
    transform: scale(2);

    &:before, &:after {
      position: absolute;
      display: block;
      content:'';
      width:14px;
      height: 1px;
      background-color:transparent;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:after {
      top: 7px;
    }

  }  
  
}

.m-menu-open {
  .nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 0;
    a {
      color: white;
      &:hover, &.active {
        color: $blue;
      }
    }    
  }
  .social {
    display: block;
    margin: 60px 20px 0 20px;
    a {
      text-decoration: none;
      color: $lightgreen;
      margin: 0 20px;      
    }
  }
  .m-menu, .nav-title {
    display: block;
  }
  .m-menu .mm {

    &:before {
      top:10px;
      transform:rotate(-45deg);
      background-color:$darkgreen;
    }
    &:after {
      top:10px;
      transform:rotate(45deg);
      background-color:$darkgreen;
    }
  }
  .nav {
    display: flex;
  }
}


@media only screen and (max-width: 70em) {
  .scrolling .nav {
    width: 50%;
  }
}

@media only screen and (max-width: 40em) {
  .m-menu {
    display: block;
  }
  
  .nav {
    display: none;
  }

  .scrolling .nav {
    width: 100%;
  }
  
}