$bgcolor: #ffffff;
$maincolor: #000;
$highlightcolor: #92aff0;
$darkgreen: #65917d;
$mediumgreen: #90bb9c;
$bggreen: #92aff0;
$lightgreen: #afccc7;
$grey: #e8e9eb;
$purple: #cdcee0;
$newgreen: #69cd8c;
$blue: #92aff0;

$maxinnerwidth: 1400px;