/* Put your layout here */

html, body {
  height: 100%;
}

#container {
  max-width: $maxinnerwidth;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  position: absolute;
  max-width: 1340px;
  background: white;
  width: calc(100% - 60px);
  height: 200px;
  z-index: 2;
  top:0;
  left:0;
  right:0;
  margin: 0 auto;
  padding: 0 30px;
  transition: all 150ms cubic-bezier(.92,.06,.12,.73);
  @include borderlineafter($maincolor);

  .logo {
    a {
      width: 167px;
      height:170px;
      background-image:url('/img/logo-big.png');
      transition: all 150ms cubic-bezier(.92,.06,.12,.73);
    }
  }
}

.logo {
  display: inline-block;
  a {
    background-size:100% 100%;
    display: block;
  }
}

.header-big .logo {
  a:hover {
    transform:translate(0, -10px);
  }
}

.uptodate {
  margin-top: 10px;
  display: inline-block;
  margin-right: 30px;
}

.header-small {
  position: fixed;
  top:-100px;
  height: 100px;
  background: white;
  @include borderlineaftersmall($maincolor);

  .logo {
    a {
      width: 68px;
      height:87px;
      background-image:url('/img/logo-small.png');

      &:hover {
        background-image:url('/img/logo-small-hover.png');
      }
    }
  }

  .menu-sc {
    position: absolute;
    right: 50px;
    top: 10px;
    cursor: pointer;
  }
}

.scrolling {
  .header-small {
    top:0;
  }
  .uptodate {
    width: auto;
    margin-top: 40px;
    margin-left: 40px;

    &:hover {
      background: $darkgreen;
    }
  }
}


.main {
  margin-top: 200px;
  flex: 1 0 auto;
  ul:not(.slick-dots){
   li {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    &:before {
      position: absolute;
      content: '';
      left:4px;
      top:10px;
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color:$maincolor;
    }
   }
  }
}

footer {
  flex-shrink: 0;
  padding: 30px 60px 60px 60px;
  display: flex;
  color: white;
  position: relative;
  justify-content: space-between;
  a {
    color: white;
    text-decoration: none;
  }

  .up {
    position: absolute;
    top:-70px;
    right:10px;
    cursor: pointer;
    background: pink;
    background-image: url(/img/arrow-big.png);
    background-size: 100% 100%;
    background-position: center 1px;
    width: 35px;
    height: 35px;
    // transform:rotate(-90deg);
    transition: all 0.05s cubic-bezier(0.445, 0.050, 0.550, 0.950);
    &:hover {
       top:-72px;
    }
  }

  .contact, .fnav, .madeby {
    width: calc(100% / 3);
    // background:pink;
  }

  .contact, .fnav {
    padding-top: 20px;
  }
  .madeby {
    img {
      float: right;
    }
  }

  .address, .follow {
    display: flex;
    margin-bottom: 15px;
  }

  .init {
    margin-top: 45px;
  }

  .label {
    width: 90px;
    border-right: 2px solid white;
    margin-right: 10px;
  }

  .fnav {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    li {
      width: 40%;
    }
  }
}


/* HOME */
.homequote {
  background: $grey;
  padding: 30px 60px 100px 60px;
  text-align: right;
  position: relative;
  z-index: -1;

  // special one to cover the green one
  &:after {
    position: absolute;
    top:0px;
    left: 30px;
    content: '';
    width: 3px;
    height: 100%;
    background: $maincolor;
    z-index: 2;
  }

  .inner {
    display: inline-block;
    // max-width: 33%;
    text-align: left;

    span {
      display: block;
    }
  }
}

.news, .tib {
  .thumb, .info {
    max-width: calc(50% - 132px);
  }

  .thumb img {
    max-width: 75%;
  }

  .info {
    padding: 30px 60px 40px 60px;
    max-width: 350px;
    .more {
      padding: 60px 0;
      text-decoration: underline;
    }
  }

  &.i1, &.i3 {

    .info {
      border-right: 4px solid $maincolor;
      order: 0;
      padding-right: 70px;
    }
    .thumb {
      order: 1;
      margin-left: -40px;
    }
  }
  &.i2, &.i4 {
    a {
      justify-content: flex-end;
    }
    .info {
      border-left: 4px solid $maincolor;
      padding-left: 70px;
    }
    .thumb {
      z-index: 1;
      img {
        float: right;
      }
      margin-right: -40px;
    }

  }
}

.news {
  margin-bottom: 60px;

  a {
    display: flex;
    text-decoration: none;
    &:hover {
      color: $maincolor;
    }
  }

  .info {
    max-width: 440px;
  }

  .thumb img {
    max-width: 90%;
  }

  &.first {
    margin-top: -80px;
    &:before {
      display: none;
    }
  }
  &.i1, &.i3 {
    .info, .thumb {
      align-self: flex-end;
    }
    .info {
      padding-right: 100px;
      padding-left:80px;
    }
  }
  &.i2, &.i4 {
    a {
      justify-content: flex-end;
    }
    .info {
      padding-right:80px;
      padding-left: 100px;
    }
  }

  &.i1 {
    .info {
      background: $newgreen;//$purple;
    }
  }

  &.i2 {
    .info {
      background: $lightgreen;
    }
  }

  &.i3 {
    .info {
      background: $grey;
    }
  }

  &.i4 {
    .info {
      background: $mediumgreen;
    }
  }
}

.newsitems, .content-blocks {
  @include borderline($mediumgreen);
  .quote, blockquote {
    text-align: center;
    padding: 30px;
    color: $blue;
    // max-width: 600px;
    margin: 0 auto 60px auto;
  }
}

.more-news {
  margin: 40px auto;
  text-align: center;
}

.gallery {
  padding: 30px 120px 120px 120px;

  .slick-arrow {
    position: absolute;
    top: calc(50% - 20px);
    &.prev {
      left: -50px;
    }
    &.next {
      right: -50px;
    }
  }
  .slick-slide {
    .inner {
      margin: 0 15px;
    }
  }

}

.slick-arrow, .slick-slide, button {
  outline: none;
}


/* REGULAR PAGE */
h1 {
  text-align: center;
}

.page {
  .intro {
    max-width: 700px;
    margin: 0 auto;
    padding: 0 30px;
  }

  .themes {
    padding: 30px 140px;
  }
  .themecarousel {
    position: relative;

    .slick-arrow {
      position: absolute;
      top: calc(50% - 20px);
      &.prev {
        left: -50px;
      }
      &.next {
        right: -50px;
      }
    }

    .theme {
      &:nth-child(5n+1) .filter {
        background: $mediumgreen;
       }
      &:nth-child(5n+2) .filter {
        background: $lightgreen;
      }
      &:nth-child(5n+3) .filter {
        background: $darkgreen;
      }
      &:nth-child(5n+4) .filter {
        background: $newgreen;//$purple;
      }
      &:nth-child(5n+5) .filter {
        background: $bggreen;
      }

      .filter {
        text-align: center;
        padding: 20px;
        margin: 5px;
        border:2px solid transparent;
        line-height: 1.35rem;//50px;
        transition: all 150ms cubic-bezier(.92,.06,.12,.73);
      }

    }
    .slick-center {
      .filter {
        margin-top:4px;
        border: 3px solid $maincolor;
        background: white !important;
        // transform: scale(1.05);
      }

    }
  }

  .questions {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: 0 auto;
    .question {
      width: calc(50% - 100px);
      margin:40px 50px;

      &.quote {
        background: $newgreen;//$purple;
        text-align: center;
        color: white;
        padding: 50px;
        width: calc(50% - 200px);
        a {
          color:white;
        }
      }
    }
  }
}

/* about & general */
.about .page-header,
.general .page-header {
  background: $darkgreen;
  display: flex;
  margin-bottom: 60px;
  padding: 30px 0 30px 0;
  position: relative;
  @include borderline($maincolor);
  &:before {
    z-index: 2;
  }

  h1 {
    width: 60%;
  }
  .intro {
    width: 40%;
    padding: 0 90px 0 30px;
  }
}

.tib {
  display: flex;
  margin-bottom: 60px;

  .info {
    width: 60%;
    max-width: calc(50% - 105px);
    .txt, h3  {
      // width: 100%;
      // p{ max-width: 300px;}
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .thumb img {
    max-width: 100%;
    width: 100%;
  }

  &.left, &.right {
    .thumb {
      width: 33%;
    }
  }

  &.left {
    justify-content: flex-end;
    .info {
      background: $newgreen;//$purple;
      padding-left: 120px;
      padding-right: 120px;
    }
    .slick-slider .slick-dots {
      position: absolute;
      top: calc(50% - 30px);
      left: -20px;
      flex-direction: column;
    }
   }
   &.right {
    .info {
      background: $grey;
      padding-right: 120px;
      padding-left: 120px;
    }
   }
   &.bottom {
    text-align: center;
    padding-bottom: 70px;
    margin-bottom: 0;
    display: block;
    position: relative;
    @include borderline($mediumgreen);
    &:before {
      z-index: 2;
    }
    .info, .thumb, .thumb img {
      max-width: none;
      width: 100%;
    }
    .info {
      background: $grey;
      max-width: calc(100% - 120px);
      border-left: 0px;
      padding-left: 60px;
      h3, .txt {
        max-width: 600px;
        margin: 0 auto;
      }
      .txt {
        // text-align: left;
      }
    }
   }

 }

 .wonen {
  .page-header {
    background-color: $grey;
  }
  .baseline {
    min-width: 200px;
    max-width: 33%;
    margin-right: 60px;
  }
  .homequote {
    padding-bottom: 30px;
    background-color: white;
    span {
      display: inline;
    }
  }
  .partner-logos {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px 60px;
    .plogo {
      // margin-left: 20px;
      max-width: 386px;//200px
    }
    // img {
    //   max-width: 100px;
    // }
  }
 }

 .slick-slider .slick-dots {
  display: flex;
  justify-content: center;
  li {
    width: 10px;
    height: 10px;
  }
  li.slick-active {
    button {
      background: $newgreen;//$purple;
    }
  }
  button {
    background: $blue;
    border: 0px;
    width: 5px;
    height: 5px;
    font-size: 0;
    padding: 0;
  }
 }

.about .imgholder,
.general .imgholder {
  display: flex;
  justify-content: flex-start;
  padding: 0 30px 120px 30px;
  .img {
    margin: 30px;
  }
}


.provincie {
  .header-img {
    background: $grey;
    padding-bottom: 25px;
    margin-bottom: 30px;
    img {
      width: 100%;
    }
  }
  h1 {
    font-size: 2.35rem;
    display: inline-block;
    padding: 30px 20px;
    margin-left: 120px;
    margin-bottom: 30px;
    border: 2px solid $maincolor;
  }
  .page-header {
    display: flex;
    padding: 30px 120px;
    margin-bottom: 60px;
    .intro {
      padding-left: 30px;
      border-left: 3px solid $maincolor;
      width: 66%;
      margin-left: 30px;
    }
  }

  .blocks {
    display: flex;
    .left,.right {
      width: 50%;
    }
    .right {
      background-color: $darkgreen;
      padding: 60px 120px;
      width: calc(50% - 240px);
    }
    .left {
      .img {
        padding: 0 120px 60px 120px;
      }
    }
    .smallblock {
      background: $newgreen;//$purple;
      padding: 60px 120px;
    }
  }

  .carouselholder {
    margin:30px 60px 120px 60px;
    .slick-slide div img{
        margin: 10px;
    }
  }
}


.themecarousel {
  visibility: hidden;
  height: 70px;
  &.slick-initialized {
    visibility: visible;
    height: auto;
  }
}

.contact-open {
  .contact-overlay {
    display: flex;
  }
}
.contact-overlay {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
    justify-content: center;
  .contact {
    background: $maincolor;
    max-width: 500px;
    margin: 0 auto;
    padding: 60px;
    position: relative;
  }
  h3 {
    color: $mediumgreen;
    text-align: center;
  }
  input, textarea{
    width: calc(100% - 20px);
    margin: 10px 0;
    padding: 10px;
    background: transparent;
    color: white;
    border: 1px solid $mediumgreen;
    border-radius: 0;
    &:before {
      position: relative;
      content: '';
      height: 10px;
      width: 1px;
      background: pink;
    }
  }
  input[type="submit"] {
    background: none;
    width: auto;
    color: $blue;
    padding: 15px 30px;
  }
  form {
    text-align: center;
    color: white;
  }
  .error {
    margin-top: 10px;
  }
}

.close {
  border:none;
  background:none;
  cursor: pointer;
  position: absolute;
  right:10px;
  top: 20px;
  display: block;
  width:26px;
  height:26px;
  outline:none;
  padding: 0; // ios has a bug otherwise
  transform: scale(2);

  &:before, &:after {
    position: absolute;
    display: block;
    content:'';
    width:14px;
    height: 1px;
    background-color:$mediumgreen;
    transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    top:10px;
    transform:rotate(-45deg);
  }
  &:after {
   transform:rotate(45deg);
  }

}

.mcf {
  background: rgba(0,0,0,0.2);
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12345;
  color: white;
  display: none;
  align-items: center;
  justify-content: center;
}
.mco .mcf, .cbo .cookiebanner {
  display: flex;
}

.close-btn {
  // background: pink;
  position: absolute;
  right: 20px;
  top:20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  &:before, &:after {
    content: '';
    display: block;
    height: 25px;
    width: 25px;
    position: absolute;
  }
  &:before {
    border-left:1px solid $darkgreen;
    transform: rotate(-45deg);
    top: -11px;
    left: 6px;

  }
  &:after {
    border-right:1px solid $darkgreen;
    transform: rotate(45deg);
    top: -11px;
    left: -12px;
  }

}
#mc_embed_signup {
  background: black;
  max-width: 400px;
  margin: 0 auto;
  padding: 50px 100px;
  position: relative;

  h3 {
    color: $darkgreen;
    margin-bottom: 40px;
  }
  input {
    width: calc(100% - 22px);
    padding: 10px 10px 13px 10px;
    margin-bottom: 20px;
    border: 1px solid $darkgreen;
    background:transparent;
    color: white;
  }
  [type="submit"] {
    max-width: 100px;
    color: $highlightcolor;
    cursor: pointer;
    outline:none;
    &:hover {
      background: $darkgreen;
      color: white;
    }
  }
  label {
    position: relative;
    padding: 10px;
    width: calc(100% - 20px);
    background: $darkgreen;
    display: block;
    &:before {
      content:'';
      position: absolute;
      border-left: 1px solid white;
      left:11px;
      top: 12px;
      height: 20px;
    }
  }
  [type="checkbox"] {
    width: auto;
    float: right;
    height: 24px;
  }

  li {
    margin-bottom: 20px;
  }

  .sm {
    text-align: center;
      margin: 0 auto;
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
    padding-left: 10px;
    border-left:1px solid white;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: white;
    padding-left: 10px;
    border-left:1px solid white;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: white;
    padding-left: 10px;
    border-left:1px solid white;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: white;
    padding-left: 10px;
    border-left:1px solid white;
  }
}

.cookiebanner {
  z-index: 99999;
  padding: 0;
  width: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  width: calc(100% - 40px);
  justify-content: space-between;
  // max-width: 550px;
  background: $mediumgreen;
  color: $maincolor;
  border-right:4px solid $maincolor;
  // h4 {
  //   padding: 0;
  //   margin: 0 0 40px 0;
  // }
  button {
    background: transparent;
    border:none;
    padding: 0;
    margin: 0 0 0 10px;
    cursor: pointer;
  }
  a {
    &:hover {
      color:$maincolor;
      text-decoration: none;
    }
  }
}

.page.pp {
  .intro {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 70em) {

  .provincie {

    .blocks {

      .left .img {
        padding-left: 60px;
        padding-right: 60px;
      }
      .right {
        padding-left: 60px;
        padding-right: 60px;
        width: calc(100% - 120px);
      }

      .smallblock {
        padding: 60px;
      }
    }
  }

  footer {
    flex-wrap: wrap;
    padding: 30px;
    .contact, .fnav {
      width: 50%;
    }
    .madeby {
      margin-top: 30px;
      width: 100%;
    }
    .fnav li {
      width: 50%;
    }
  }

}

@media only screen and (max-width: 60em) {

  .news .info, .tib .info {
    padding-left: 20px;
    padding-right: 20px;
  }
  .news.i1 .info, .news.i3 .info {
    padding-left: 20px;
    padding-right: 60px;
  }
  .news .thumb, .tib .thumb {
    max-width: 50%;
  }

  // about
  .tib.right .info {
    padding-left: 20px;
    padding-right: 60px;
  }
  .tib.left .info {
    padding-left: 60px;
    padding-right: 20px;
  }
  .tib.left .thumb, .tib.right .thumb {
    width: 50%;
  }

  .provincie {

    .blocks {

      .left .img {
        padding-left: 20px;
        padding-right: 20px;
      }
      .right {
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 40px);
      }

      .smallblock {
        padding: 60px;
      }
    }
  }

}

@media only screen and (max-width: 45em) {

  .page {
    .themes {
      padding: 30px 70px;
    }
    .questions {
      display: block;
      .question {
        width: calc(100% - 100px);
        &.quote {
          width: calc(100% - 200px);
        }
      }
    }
  }

  .about .page-header,
  .general .page-header {
    display: block;
    h1, .intro {
      width: calc(100% - 100px);
      padding: 0 50px 0 50px;
      text-align: left;
    }
  }

  .wonen .page-header .baseline {
    padding-left: 50px;
    text-align: right;
    max-width: calc(100% - 50px);
    margin-right: 30px;
  }

  .tib.right .info, .tib.left .info {
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(60% - 10px);
  }

  .tib.bottom .info {
    padding-left: 50px;
    padding-right: 50px;
    max-width: calc(100% - 100px)
  }
  .about .imgholder,
  .general .imgholder {
    display: block;
  }

  .provincie {
    .page-header {
      display: block;
      padding: 30px 50px;
    }

    h1 {
      margin-left: 50px;
    }
    .blocks {
      flex-direction:column;
      .left, .right {
        width: 100%;
      }
      .right {
        order: 1;
        padding: 15px 60px 30px 60px;
        width: calc(100% - 120px);
      }
      .left {
        order: 2;
      }
      .smallblock {
        padding: 60px;
      }
    }
  }

  #mc_embed_signup {
    padding: 20px;
    h3 {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 40em) {
  footer {
    display: block;
    .contact, .fnav {
      width: 100%;
    }
  }
  .header-small .menu-sc {
    position: fixed;
  }
  .homequote .inner {
    display: block;
    max-width: 100%;
  }
  .news .info, .tib .info {
    padding: 30px;
    border-left:0px !important;
    border-right:0px !important;
  }
  .gallery {
    padding: 30px 60px 120px 60px;
  }

  .provincie {
    .page-header .intro {
      padding: 0;
      width: 100%;
      border: none;
      margin-left: 0;
    }
    .blocks .left .img{
      padding: 0
    }
    .blocks .smallblock{
      padding: 20px;
    }
    .blocks .right {
      width: calc(100% - 40px);
      padding-left: 20px;
      padding-right: 20px;
    }

  }
  .newsitems .news, .content-blocks {
    a, .tib {
      display: block;
    }
    .info, .thumb, .tib.right .thumb, .tib.left .thumb, .tib.right .info, .tib.left .info {
      display: block;
      width: 100%;
      max-width: calc(100% - 40px);
      padding: 20px;

      img {
        float: none;
      }
    }

    .thumb {
      padding: 0 !important;
      margin: 0 !important;
      max-width: 100%;
    }
    .tib.left .thumb {
      margin-left: 30px !important;
    }
  }




}
@media only screen and (max-width: 30em) {
  footer .fnav li {
    width: 100%;
  }

  .cookiebanner {
    flex-direction: column;
    justify-content: flex-start;
    button {
      width: auto;
      margin: 10px 0 5px 0;
      text-align: left;
    }
  }
}
