@font-face {
  font-family: 'pangramextrabold';
  src: url('/webfonts/pangram-extrabold-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pangrammedium';
  src: url('/webfonts/pangram-medium-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pangramblack';
  src: url('/webfonts/pangram-black-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pangrambold';
  src: url('/webfonts/pangram-bold-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pangramlight';
  src: url('/webfonts/pangram-light-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'pangramregular';
  src: url('/webfonts/pangram-regular-webfont.woff2') format('woff2'),
  url('/webfonts/pangram-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: 'pangramregular',Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;
}

button, input, optgroup, select, textarea {
  font-family: 'pangramregular',Helvetica Neue, sans-serif;
}

h1 {
  line-height: 2rem;
}
h2 {
  line-height: 1.75rem;
}

.wonen h2 {
  // font-size: 2rem;
  // line-height: 2rem;
}

.provincie h1 {
  font-family: pangramlight;
}

.uptodate, .special {
  font-size: 1rem;
}

.homequote, .wonen .baseline {
  font-family: 'pangrambold';
  font-size: 3rem;
  line-height: 3rem;
}

.menu-sc {
  font-size: 2rem;
  line-height: 2rem;
}

.m-menu-open nav{
  font-size: 1.5rem;
  line-height: 2rem;
}

.nav a {
  font-size: 1.5rem;
  font-family: 'pangramlight';
}
.nav .social a{
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.quote {
  font-family: 'pangrambold';
  font-size: 2.25rem; // font-weight: bold;
  line-height: 3.25rem;
}

.more {
  font-size: 1.5rem;
  font-family: 'pangrammedium';
}
.more-news {
  font-size: 1.5rem;
  font-family: 'pangrammedium';
}

.slick-center {
  font-size: 1.25rem;
}

.question.quote {
  line-height: 2.75rem;
  a {
    font-size: 1.25rem;
  }
}

footer {
  .contact {
      .label {
          font-size: 1rem;
      }
      font-size: 0.8rem;
      line-height: 1rem;
  }
  .fnav {
      font-size: 1.15rem;
  }
  .init, .credits {
    font-size: 0.7rem;
  }
}

.cookiebanner {
  h4 {
    font-size: 1.75rem;
    line-height: 2rem;
  }
  p {
    font-size: 1.25rem;
    font-family: pangramlight;
  }
  button {
    font-size: 1rem;
    font-family: 'pangrambold';
    text-decoration: underline;
  }
}

span.k {
  letter-spacing: -0.2rem;
}

@media only screen and (max-width: 45em) {
  .homequote, .wonen .baseline  {
    font-size: 2rem;
    line-height: 2.2rem;
  }
  .quote {
    font-size: 1.65rem;
    line-height: 1.75rem;
  }

  .provincie h1 {
    font-size: 1.75rem !important;
  }

}