/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'slick';
@import 'mixins';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

body {
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;
}

ul,
li {
  list-style: none;
  margin:0;
  padding:0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.active, a:hover {
  color: $highlightcolor;
}

.slick-slider {
  button {
    border: none;
    width: 40px;
    height: 40px;
    border: 1px solid $darkgreen;
    background-image: url('/img/arrow-small.png');
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
  }
  .prev {
    border: 1px solid $darkgreen;
    transform: rotate(-180deg);
  }
}

.main {
  position: relative;
  // @include borderline($maincolor);
}

.btn {
  border: 3px solid $maincolor;
  text-decoration: none;
  padding: 10px 30px;

  &:hover {
    background: $maincolor;
    color: white;
  }
}

.uptodate, .special {
  background:$highlightcolor;
  color: white;
  text-decoration: none;
  padding: 3px 15px 7px 15px;
  cursor: pointer;

  &:hover {
    background: $maincolor;
    color: white;
  }
}



input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  [type="checkbox"]+span {
    position: relative;
    padding-left: 12px;
    padding-right:24px;
    cursor: pointer;
    display: block;
    // height: 25px;
    // line-height: 25px;
    padding-top: 2px;
    line-height: 1.35;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      right: 0;
      width: 20px;
      height: 20px;
      z-index: 0;
      border: 1px solid $darkgreen;
      background: white;
      border-radius: 0;
    }
  }

  [type="checkbox"]:checked+span {
    &:before {
      background: white;
    }

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      right: 5px;
      width: 11px;
      height: 7px;
      z-index: 0;
      border-top: 1px solid $darkgreen;
      border-right: 1px solid $darkgreen;
      transform: rotate(135deg);
      border-radius: 0;
    }

  }

footer {
  background: $maincolor;
}
